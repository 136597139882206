.svg-container {
  position: "absolute";
}

.your-svg-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 20s linear forwards !important;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}