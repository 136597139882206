.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.content {
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumbotron-image {
  opacity: 0;
  transition: opacity 1s ease; /* Smooth transition for opacity */
}

/* Apply a zoom-in effect to the active image */
.jumbotron-image.active {
  opacity: 1;
  animation: zoom-in 20s ease-in-out infinite;
}
/* Keyframes animation for the zoom-in and zoom-out effect */
@keyframes zoom-in {
  0% {
    transform: scale(1); /* Initial scale - no zoom */
  }
  50% {
    transform: scale(1.1); /* Zoom in halfway through the animation */
  }
  100% {
    transform: scale(1.1); /* Zoomed state at the end of the animation */
  }
}

@font-face {
  font-family: 'Catesque';
  src: url('./assets/catesque.woff2') format('woff2'),
       url('./assets/catesque.woff') format('woff');
}



.navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  margin: 0 5px; /* Adjust spacing between circles */
  transition: background-color 0.3s ease-in-out;
  opacity: 0.5;
}

/* Style for the active circle (corresponding to the displayed image) */
.circle.active {
  background-color: #0054a6;
  opacity: 1;
}



a#menu-icon {
  display: inline-block;
  color: #0054a6;
  transition: transform 0.5s;
  height: 5px;
  margin-right: 9px;
  margin-top: 5px;
}

a#menu-icon:hover {
  cursor: pointer;
}

a#menu-icon .bar {
  margin: 3px;
  width: 25px;
  height: 3px;
  background: #0054a6;
  border-radius: 1.5px;
  transition: margin 0.3s, transform 0.3s;
  -webkit-transition: margin 0.4s, -webkit-transform 0.3s; 
  transition: margin 0.3s, -webkit-transform 0.3s;
  -o-transition: margin 0.3s, transform 0.3s;
  transition: margin 0.3s, transform 0.3s;
  transition: margin 0.3s, transform 0.3s, -webkit-transform 0.3s;
}


a#menu-icon :hover .bar {
  -webkit-animation: transformY(6px) rotate(45deg);
  animation: transformY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
          transform: translateY(6px) rotate(45deg);
}

a#menu-icon.close .bar:first-child {
  -webkit-transform: translateY(6px) rotate(45deg);
      -ms-transform: translateY(6px) rotate(45deg);
          transform: translateY(6px) rotate(45deg);
  background: white;
  transform-origin: center center;
}
a#menu-icon.close .bar:nth-child(2) {
  opacity: 0;
}
a#menu-icon.close .bar:last-child {
  -webkit-transform: translateY(-6px) rotate(-45deg);
      -ms-transform: translateY(-6px) rotate(-45deg);
          transform: translateY(-6px) rotate(-45deg);
  background: white;
  transform-origin: center center;
}



/* For the second bar of the "X" (this one remains hidden) */
a#menu-icon.close:hover .bar:nth-child(2) {
  opacity: 0;
}


#btn {
  position: absolute;
  width: 25px;
  height: 5px;
  top: 45%;
  right: 1.4%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background: transparent;
  border-radius: 50%;
  border: none;
  outline: none; }
  #btn span {
    position: absolute;
    width: 25px;
    height: 3px;
    top: 50%;
    left: 50%;
    background: #0054a6;
    border-radius: 2px;
    overflow: hidden;
    transition: all 0.3s linear; }
    #btn span::before {
      content: '';
      position: absolute;
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      background: white;
      transition: all 0.3s linear; }
    #btn span:nth-child(1) {
      animation: span-first-off 0.5s ease-in-out;
      animation-fill-mode: forwards; }
    #btn span:nth-child(2) {
      animation: span-second-off 0.5s ease-in-out;
      animation-fill-mode: forwards; }
    #btn span:nth-child(3) {
      animation: span-third-off 0.5s ease-in-out;
      animation-fill-mode: forwards; }

#btn.on:hover span::before {
  width: 100%;
  transition: all 0.3s linear;
}

#btn.on span:nth-child(1) {
  animation: span-first-on 0.5s ease-in-out;
  animation-fill-mode: forwards; }

#btn.on span:nth-child(2) {
  animation: span-second-on 0.5s ease-in-out;
  animation-fill-mode: forwards; }

#btn.on span:nth-child(3) {
  animation: span-third-on 0.5s ease-in-out;
  animation-fill-mode: forwards; }

@keyframes span-first-on {
  0% {
    transform: translate(-50%, -300%); }
  30% {
    transform: translate(-50%, -50%); }
  100% {
    transform: translate(-50%, -50%) rotate(-45deg); } }

@keyframes span-first-off {
  0% {
    transform: translate(-50%, -50%) rotate(-45deg); }
  30% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -300%); } }

@keyframes span-second-on {
  0% {
    transform: translate(-50%, -50%); }
  25% {
    background: black; }
  50% {
    transform: translate(-50%, -50%) scale(1); }
  100% {
    transform: translate(-150%, -50%) scale(0); } }

@keyframes span-second-off {
  0% {
    transform: translate(-150%, -50%) scale(0); }
  25% {
    background: black; }
  50% {
    transform: translate(-50%, -50%) scale(1); }
  100% {
    transform: translate(-50%, -50%); } }

@keyframes span-third-on {
  0% {
    transform: translate(-50%, 200%); }
  30% {
    transform: translate(-50%, -50%); }
  100% {
    transform: translate(-50%, -50%) rotate(45deg); } }

@keyframes span-third-off {
  0% {
    transform: translate(-50%, -50%) rotate(45deg); }
  30% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, 200%); } }

