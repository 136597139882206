.login-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-left {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-right {
  background-color: #f5f5f5;
  height: 100%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .login-right {
      display: none;
      position: relative;

  }
  .login-left {
      flex: 0 0 100%;
      position: absolute;
      top: 50%; /* Center vertically */
      left: 50%; /* Center horizontally */
      transform: translate(-50%, -38%); /* Adjust for the element's own size */
      height: 100%;
      width: 100%;
  }
  
  
}
  .login-header {
    margin-top: 7%;
    margin-bottom: 1%;
  }
  
  .login-header h2 {
    font-size: 3rem;
  }
  
  .login-header p {
    font-size: 17px;
  }
  
  .login-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }